@import './themes';

@mixin nb-overrides() {
  nb-select.size-medium button {
    padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;

    nb-icon {
      right: 0.41rem !important;
    }
  }

  nb-stepper .header .step.completed .label-index {
    background-color: #00d68f;
    border-color: #00d68f;
  }
}
